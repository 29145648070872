export const CMS_ITEMS = {
  CATALOGUE_SOURCE: "catalogue_source",
  SERVICE_CATEGORY: "service_category",
  SERVICE: "service",
  AIRLINE: "airlines",
};

export const CMS_PARAMS = {
  catalogue_source: {
    fields: "id,name,taxonomy_code",
    deep: "*",
  },
  service_category: {
    fields: "id,icon,name,taxonomy_code",
    deep: "*",
  },
  service: {
    fields: `id,name,taxonomy_code,description,icon,image,svg_icon,airline_content.order_quantity_uom.code`,
    deep: `{ "airline_content": { "_filter": { "airlines_id": { "short_code": { "_eq": "SQ" }}}}}`,
  },
  airline: {
    fields: "id, name, icon, small_icon, display_name, short_code",
  },
};
