import CHOICES from "CHOICES";
import { mutate } from "utils/relay";
import {
  AUTH_TOKEN,
  DEFAULT_IATA_ID,
  DEFAULT_IATA_NUMBER,
  ENV_MAPPER,
  IATA_LIST,
  REFRESH_TOKEN,
  TOKEN_TTL,
  DEFAULT_IATA_CURRENCY,
} from "utils/constants";
import {
  emptyProxyObject,
  getEnv,
  getSessionStorage,
  ProxyObjectType,
} from "utils/utils";
import { Action, Thunk, Computed, thunk, action, computed } from "easy-peasy";
import { Cookies } from "react-cookie";
import graphql from "babel-plugin-relay/macro";
import moment from "moment";

const mutation = graphql`
  mutation authRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
      refreshToken
    }
  }
`;

const cookie = new Cookies();

const env = getEnv();

const cookieDomain = (env: string) =>
  env === ENV_MAPPER.production ? ".nuflights.com" : `.${env}.nuflights.com`;

const UK = CHOICES.UserKind;

export interface CurrentUserModel {
  username: string;
  email: string;
}

export interface AuthModel {
  currentUser: CurrentUserModel | ProxyObjectType;
  authToken: string | null;
  iatas: string | null;
  validUserIata: boolean;
  defaultIata: string | null;
  defaultIataID: string | null;
  isLoggedIn: Computed<AuthModel, boolean>;
  isAdmin: Computed<AuthModel, boolean>;
  setAuthToken: Action<AuthModel, string | null>;
  setValidUserIata: Action<AuthModel, string | boolean>;
  setIataNumber: Action<AuthModel, string | null>;
  setDefaultIataNumber: Action<AuthModel, string | null>;
  setDefaultIataID: Action<AuthModel, string | null>;
  setCurrentUser: Action<AuthModel, CurrentUserModel | ProxyObjectType>;
  updateAuthToken: Thunk<AuthModel, any>;
  setDefaultIataNumbers: Thunk<AuthModel, string>;
  clearAuthToken: Thunk<AuthModel>;
  fetchAuthToken: Thunk<AuthModel, any>;
  initRefreshToken: Thunk<AuthModel, boolean>;
  permissions: any;
  setPermissions: Action<AuthModel, any>;
  updatePermissions: Thunk<AuthModel, any>;
  refreshToken: string | null;
  setRefreshToken: Action<AuthModel, string | null>;
  updateRefreshToken: Thunk<AuthModel, any>;
  clearRefreshToken: Thunk<AuthModel>;
  defaultIataCurrency: string | null;
  setDefaultIataCurrency: Action<AuthModel, string | null>;
  clearIatas: Action<AuthModel>;
}

const AuthStore: AuthModel = {
  validUserIata: false,
  permissions: {},
  currentUser: emptyProxyObject,
  authToken: cookie.get(AUTH_TOKEN),
  iatas: getSessionStorage(IATA_LIST),
  defaultIata: window.sessionStorage.getItem(DEFAULT_IATA_NUMBER),
  defaultIataID: window.sessionStorage.getItem(DEFAULT_IATA_ID),
  defaultIataCurrency: window.sessionStorage.getItem(DEFAULT_IATA_CURRENCY),
  refreshToken: cookie.get(REFRESH_TOKEN),

  isLoggedIn: computed((state: any) => state.currentUser !== emptyProxyObject),
  isAdmin: computed((state: any) => state.currentUser.kind === UK.ADMIN),

  setAuthToken: action((state: any, authToken) => {
    state.authToken = authToken;
  }),

  setRefreshToken: action((state: any, refreshToken) => {
    state.refreshToken = refreshToken;
  }),

  setValidUserIata: action((state: any, currentState: boolean) => {
    state.validUserIata = Boolean(currentState);
  }),

  setPermissions: action((state: any, permissions) => {
    state.permissions = permissions;
  }),

  setIataNumber: action((state: any, iatas: string) => {
    const localIatas = JSON.parse(iatas) || [];
    const iataNumber =
      localIatas?.find((edge: any) => edge.node.isDefault)?.node?.number ||
      localIatas[0]?.node?.number ||
      null;
    const iataID = localIatas.find(
      (iata: any) => iata?.node?.number === iataNumber
    )?.node?.id;
    window.sessionStorage.setItem(DEFAULT_IATA_NUMBER, iataNumber);
    window.sessionStorage.setItem(DEFAULT_IATA_ID, iataID);
    window.sessionStorage.setItem(IATA_LIST, String(iatas));
    state.iatas = JSON.parse(iatas);
    state.defaultIata = iataNumber;
    state.defaultIataID = iataID;
  }),

  setCurrentUser: action((state: any, currentUser) => {
    state.currentUser = currentUser;
  }),

  updatePermissions: thunk((actions, permissions) => {
    let permissionsObj: any = {};
    permissions &&
      permissions
        ?.split(" ")
        ?.map((permission: string) => (permissionsObj[permission] = true));
    actions.setPermissions(permissionsObj);
  }),

  updateAuthToken: thunk((actions, authToken) => {
    getEnv() !== ENV_MAPPER.local
      ? cookie.set(AUTH_TOKEN, authToken.self, {
          domain: cookieDomain(getEnv()),
          path: "/",
          sameSite: "strict",
          secure: true,
          maxAge: authToken?.expiry,
        })
      : cookie.set(AUTH_TOKEN, authToken.self, { path: "/" });
    actions.setAuthToken(authToken.self);
  }),

  updateRefreshToken: thunk((actions, refreshToken) => {
    getEnv() !== ENV_MAPPER.local
      ? cookie.set(REFRESH_TOKEN, refreshToken.self, {
          domain: cookieDomain(getEnv()),
          path: "/",
          sameSite: "strict",
          secure: true,
          maxAge: refreshToken?.expiry,
        })
      : cookie.set(REFRESH_TOKEN, refreshToken.self, { path: "/" });
    actions.setRefreshToken(refreshToken.self);
  }),

  setDefaultIataNumber: action((state: any, iataNumber: string) => {
    state.defaultIata = iataNumber;
    window.sessionStorage.setItem(DEFAULT_IATA_NUMBER, iataNumber);
  }),

  setDefaultIataID: action((state: any, iataID: string) => {
    state.defaultIataID = iataID;
    window.sessionStorage.setItem(DEFAULT_IATA_ID, iataID);
  }),

  setDefaultIataNumbers: thunk((actions, iatas) => {
    actions.setIataNumber(iatas);
  }),

  setDefaultIataCurrency: action((state: any, iataCurrency: string) => {
    state.defaultIataCurrency = iataCurrency;
    window.sessionStorage.setItem(DEFAULT_IATA_CURRENCY, iataCurrency);
  }),

  clearAuthToken: thunk(async (actions) => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    env !== ENV_MAPPER.local
      ? await cookie.remove(AUTH_TOKEN, {
          domain: cookieDomain(env),
          path: "/",
        })
      : await cookie.remove(AUTH_TOKEN, { path: "/" });
    actions.setAuthToken(null);
    actions.setCurrentUser(emptyProxyObject);
  }),

  clearRefreshToken: thunk(async (actions) => {
    window.localStorage.clear();
    getEnv() !== ENV_MAPPER.local
      ? await cookie.remove(REFRESH_TOKEN, {
          domain: cookieDomain(getEnv()),
          path: "/",
        })
      : await cookie.remove(REFRESH_TOKEN, { path: "/" });
    actions.setRefreshToken(null);
    actions.setCurrentUser(emptyProxyObject);
  }),

  fetchAuthToken: thunk(
    (actions, { mutation, input, onSuccess, onFailure }) => {
      mutate({
        mutation,
        input: input,
        onSuccess: function (data: any) {
          let iatas;
          let token: any = {
            self: "",
            expiry: new Date(),
          };
          let refreshToken: any = {
            self: "",
            expiry: new Date(),
          };
          let unixTime = Number(moment.unix(data.login.refreshExpiresIn || 0));
          let date = new Date(unixTime);
          if (data.login) {
            token.self = data.login.token;
            token.expiry = date;
            refreshToken.self = data.login.refreshToken;
            refreshToken.expiry = date;
            iatas = JSON.stringify(data.login.user.iatas.edges);
          } else if (data.socialAuth) {
            token = data.socialAuth.token;
          }
          actions.updateAuthToken(token);
          actions.updateRefreshToken(refreshToken);
          actions.setDefaultIataNumbers(String(iatas));
          onSuccess(data);
        },
        onFailure: function (messages: []) {
          actions.clearAuthToken();
          actions.clearRefreshToken();
          onFailure(messages);
        },
      });
    }
  ),

  initRefreshToken: thunk((actions, initialFetch = true) => {
    if (initialFetch && cookie.get(REFRESH_TOKEN)) {
      mutate({
        mutation,
        input: { refreshToken: cookie.get(REFRESH_TOKEN) },
        onSuccess: function (data: any) {
          let unixTime = Number(
            moment.unix(data.refreshToken.payload.exp || 0)
          );
          let date = new Date(unixTime);
          let token: any = {
            self: data.refreshToken.token,
            expiry: date,
          };
          let refreshToken: any = {
            self: data.refreshToken.refreshToken,
            expiry: date,
          };
          actions.updateAuthToken(token);
          actions.updateRefreshToken(refreshToken);
        },
        onFailure: function (messages: any) {
          actions.clearAuthToken();
          actions.clearRefreshToken();
        },
      });
    }
    setInterval(() => {
      if (cookie.get(REFRESH_TOKEN)) {
        mutate({
          mutation,
          input: { refreshToken: cookie.get(REFRESH_TOKEN) },
          onSuccess: function (data: any) {
            let unixTime = Number(
              moment.unix(data.refreshToken.payload.exp || 0)
            );
            let date = new Date(unixTime);
            let token: any = {
              self: data.refreshToken.token,
              expiry: date,
            };
            let refreshToken: any = {
              self: data.refreshToken.refreshToken,
              expiry: date,
            };
            actions.updateAuthToken(token);
            actions.updateRefreshToken(refreshToken);
          },
          onFailure: function (messages: any) {
            actions.clearAuthToken();
            actions.clearRefreshToken();
          },
        });
      }
    }, TOKEN_TTL);
  }),
  clearIatas: action((state) => {
    state.iatas = null;
  }),
};

export default AuthStore;
