import { fetchNdcQuery } from "utils/relay";
import { emptyProxyObject, ProxyObjectType } from "utils/utils";
import { Action, Thunk, thunk, action } from "easy-peasy";

export interface IAirportList {
  airportCode: string;
  airportName: string;
  cityName: string;
  countryName: string;
  countryCode: string;
  longitude: string;
  latitude: string;
}
export interface IAirportModel {
  airport: IAirportList | ProxyObjectType;
  airports: IAirportList[];
  clearAirports: Action<IAirportModel>;
  setAirports: Action<IAirportModel, IAirportList[]>;
  fetchAirports: Thunk<IAirportModel, any>;
  updateAirports: Thunk<IAirportModel, any>;
}

const AirportStore: IAirportModel = {
  airport: emptyProxyObject,
  airports: [],

  clearAirports: action((state) => {
    state.airports = [];
  }),

  setAirports: action((state, airports) => {
    state.airports = airports;
  }),

  fetchAirports: thunk((actions, { query, variables }) => {
    actions.clearAirports();
    fetchNdcQuery(query, variables).then((data: any) =>
      actions.setAirports(data?.airports || [])
    );
  }),

  updateAirports: thunk((actions, airports) => {
    actions.setAirports(airports);
  }),
};

export default AirportStore;
